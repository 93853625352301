import * as React from "react";
import {useRecordContext} from 'react-admin';
import style from '../styles.module.scss'

const OrderDrivers = () => {
    const record = useRecordContext();

    return record ? (
        <div>
            <a href="#" className={style["body2-b"]}>I0{record.id}</a><br/><br/>

            {record.students.map(function (object, i) {
                return <div key={i} className={style["mb-8"]}><a href="#">{object}</a></div>;
            })}
        </div>
    ) : null;
};

export default OrderDrivers;