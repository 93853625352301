import * as React from "react";
import {useState} from "react";
import {useRecordContext} from 'react-admin';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {CancelOutlined, ModeEditOutlined, MoreVert} from "@mui/icons-material";
import style from '../styles.module.scss'

const OrderActions = ({source}) => {
    const record = useRecordContext();
    const [activeMenu, setActiveMenu] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
        setActiveMenu(e.currentTarget.id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return record ? (
        <div style={{marginTop: 0}}>
            <div className={style["dropdown-custom"]}>
                <IconButton
                    onClick={handleOpen}
                    id={'dotMenu'}
                    style={{
                        padding: 0,
                        borderRadius: '8px',
                        backgroundColor: '#FAFBFC',

                    }}
                >
                    <MoreVert fontSize={'small'} sx={{
                        padding: '5px',
                        borderRadius: '8px',
                        '&:hover': {
                            backgroundColor: '#E5E9ED',
                        },
                    }}/>
                </IconButton>
                {activeMenu === 'dotMenu' &&
                <Menu
                    id={'dotMenu'}
                    className={`${style.dropMenu}`}
                    open={open}
                    anchorEl={anchorEl}
                    onClick={handleClose}
                >
                    <MenuItem className={style.dropitem}>
                        <ModeEditOutlined fontSize={'small'} sx={{paddingRight:'10px'}}/>Edit
                    </MenuItem>
                    <MenuItem className={style.dropitem}>
                        <CancelOutlined fontSize={'small'} sx={{paddingRight:'10px'}}/>Cancel
                    </MenuItem>
                </Menu>
                }
            </div>
        </div>
    ) : null;
}

export default OrderActions;