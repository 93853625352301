import * as React from "react";
import {useRecordContext} from 'react-admin';
import {applyOrderStyles} from "../../helpers/apply-order-styles";

const OrderAmount = ({}) => {
    const record = useRecordContext();

    return record ? (
        <div>
            <div className={applyOrderStyles('body2-b', 'mb-4')}>${record.total}</div>
            <div
                className={applyOrderStyles('text-red', 'mb-12', 'fs-12')}>{record.discount !== '0.00' ? '$' + record.discount + ' Off' : ''}</div>
            <div
                className={applyOrderStyles("text-gray-3", 'mb-8')}>{record.tax !== '0.00' ? 'Tax:' + record.tax : ''}</div>

            {Object.entries(record.payments.payments).map((t, k) => <div key={k}
                                                                         className={applyOrderStyles("text-gray-3", 'mb-8')}>${t[1].amount}<br/>Paid
                by {t[1].payment_method.name} </div>)}

        </div>
    ) : null;
};

export default OrderAmount;