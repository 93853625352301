import React, {useState} from "react";
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import style from '../styles.module.scss'

const OrderDetails = ({data, isDetailsAvailable = true}) => {
    const [showDetails, setShowDetails] = useState(false)
    return (
        <div className={style["order-details"]}>
            <div className={style["scroll-wrap"]}>
                <div className={style.top}>
                    <div>
                        <div className={style.count}>3,273</div>
                        <div className={style.title}>Purchases</div>
                        <div>
                            <div>2 Orders</div>
                            <div>4 Sub-orders</div>
                            <div>4 Purchasers</div>
                        </div>
                    </div>
                    <div>
                        <div className={style.count}>200</div>
                        <div className={style.title}>Experience</div>
                        <div>
                            <div>3 Suttle</div>
                            <div>10 Discovery Laps</div>
                            <div>2 Ride-Along</div>
                        </div>
                    </div>
                    <div>
                        <div className={style.count}>300</div>
                        <div className={style.title}>Drivers and Guests</div>
                        <div>
                            <div>24 Students – 34 Guests</div>
                            <div>14 Drivers – 2 Pass</div>
                        </div>
                    </div>
                    <div>
                        <div className={style.count}>22,826</div>
                        <div className={style.title}>Add-ons</div>
                        <div>
                            <div>18 Add-ons</div>
                        </div>
                    </div>
                    <div>
                        <div className={style.count}>$3,245,380.59</div>
                        <div className={style.title}>Payments</div>
                        <div>
                            <div>$100 Ordered</div>
                            <div>$100 Paid</div>
                            <div>$100 Tax</div>
                        </div>
                    </div>
                    <div>
                        <div className={style.count}>Status</div>
                        <div className={style.title}>Pending</div>
                    </div>
                </div>
            </div>
            {showDetails &&
            <div className={style.down}>
                <div className={style.body}>
                    <div>
                        <div>588 Google</div>
                        <div>785 Not Listed Above</div>
                        <div>518 Online Travel Agency (OTA)</div>
                        <div>145 Grand Bazaar Shops</div>
                        <div>987 Friend / Colleague</div>
                        <div>571 Return Customer</div>
                        <div>460 Hotel Concierge</div>
                        <div>426 Facebook</div>
                        <div>243 Vegas.com</div>
                        <div>239 Trip Advisor</div>
                        <div>195 OnSite Order</div>
                        <div>195 YouTube</div>
                        <div>179 Instagram</div>
                        <div>147 Santa Monica Store</div>
                        <div>145 Car And Driver</div>
                    </div>
                    <div>
                        <div>87 Driving Sessions</div>
                        <div>85 Laps</div>
                        <div>72 Shuttle</div>
                        <div>53 Discovery Laps</div>
                        <div>27 Ride-Along</div>
                    </div>
                    <div>
                        <div className={style.item}>
                            Philippe Wilson
                            <small>Individual</small>
                        </div>
                        <div className={style.item}>
                            Elisabeth Mason
                            <small>Individual</small>
                        </div>
                        <div className={style.item}>
                            John Doe
                            <small>Individual</small>
                        </div>
                        <div className={style.item}>
                            Carl Melone
                            <small>Individual</small>
                        </div>
                        <div className={style.item}>
                            Jack Durant
                            <small>Individual</small>
                        </div>
                    </div>
                    <div>
                        <div>3,635 On-Board HD Video</div>
                        <div>982 On Board HD Video Corpo</div>
                        <div>760 On-Board HD Video</div>
                        <div>422 On-Board HD Video</div>
                        <div>354 Service Fee $49 per car</div>
                        <div>303 1 Passenger</div>
                        <div>300 1 Free Lap on Your Driving</div>
                        <div>243 Z Report – Tax difference</div>
                        <div>216 Premium Insurance Package</div>
                        <div>123 Service Charge</div>
                        <div>104 On-Board Video</div>
                    </div>
                    <div>
                        <div className={style.item}>
                            $15,639,963.99 Paid
                            <small>by Credit Card</small>
                        </div>
                        <div className={style.item}>
                            $881,895.11 Paid
                            <small>by Retailer</small>
                        </div>
                        <div className={style.item}>
                            -$29,415.4 Paid
                            <small>by Adjustment</small>
                        </div>
                        <div className={style.item}>
                            $959,269.68 Paid
                            <small>by Cash</small>
                        </div>
                        <div className={style.item}>
                            $367,615.2 Paid
                            <small>by Paypal</small>
                        </div>
                        <div className={style.item}>
                            $352,723.1 Paid
                            <small>by Free</small>
                        </div>
                    </div>
                </div>
            </div>
            }
            {isDetailsAvailable &&
            <div className={style["show-less"]}>
                <div onClick={() => setShowDetails(!showDetails)}>
                    {showDetails ?
                        <span style={{display: 'flex', alignItems: 'center'}}>
                            Hide Details<KeyboardArrowUp/>
                        </span>
                        :
                        <span style={{display: 'flex', alignItems: 'center'}}>
                            Show Details<KeyboardArrowDown/>
                        </span>}
                </div>
            </div>
            }
        </div>
    );
}
export default OrderDetails