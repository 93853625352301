import React, {useState} from 'react';
import {Button, Menu, MenuItem, TextField} from '@mui/material';
import {ArrowCircleUp, FilterList, Print, Search, SwapVert} from '@mui/icons-material';
import style from '../styles.module.scss'
import {applyOrderStyles} from '../../helpers/apply-order-styles';

const ToolBar = ({onChange, isSpecifiedOrder = false}) => {
    const [activeMenu, setActiveMenu] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        setActiveMenu(e.currentTarget.id);
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div className={style['filter-page']}>
            <div className={style['form-group']}
                 style={!isSpecifiedOrder ? {margin: '0 8px'} : {margin: 0, float: 'left'}}>
                <div className={style['wrap-input']}>
                    <TextField
                        type='text'
                        variant='standard'
                        placeholder='Search...'
                        className={style['form-control']}
                        sx={{
                            margin: 0,
                            minWidth: '400px',
                            '& .MuiInputBase-root': {
                                padding: '0 12px',
                            },
                            '& .MuiInputBase-input': {
                                fontSize: 14,
                                padding: 0,
                                '&::placeholder': {
                                    fontWeight: 500,
                                    color: '#18191C',
                                    opacity: 0.5
                                }
                            },
                        }}

                        InputProps={{disableUnderline: true, endAdornment: <Search sx={{color: '#D00209'}}/>}}
                    />
                </div>
            </div>
            <div>
                <Button className={applyOrderStyles('btn', 'btn-default', 'with-shadow')} sx={{
                    color: 'black',
                    backgroundColor: '#fff',
                    textTransform: 'unset',
                    border: '1px solid white',
                    fontSize: '14px',
                    borderRadius: '8px',
                    padding: '0 16px',
                    fontWeight: 600,
                    '&:hover': {
                        borderColor: '#D00209',
                        color: '#D00209',
                        '& svg': {
                            color: '#D00209'
                        }
                    },
                }} startIcon={<FilterList sx={{color: '#D00209'}}/>} onClick={onChange}> Filters
                </Button>
            </div>
            {!isSpecifiedOrder &&
            <div className={style['dropdown-custom']}>
                <Button
                    id={'sort'}
                    aria-controls={'sort'}
                    className={applyOrderStyles('btn', 'btn-default', 'with-shadow')}
                    sx={{
                        color: 'black',
                        backgroundColor: '#fff',
                        textTransform: 'unset',
                        border: '1px solid white',
                        fontSize: '14px',
                        borderRadius: '8px',
                        fontWeight: 600,
                        padding: '0 17px',
                        position: 'relative',
                        '&:hover': {
                            borderColor: '#D00209',
                            color: '#D00209',
                            '& svg': {
                                color: '#D00209'
                            }
                        },
                    }}
                    startIcon={<SwapVert sx={{color: '#D00209'}}/>}
                    onClick={anchorEl === null ? handleOpen : handleClose}
                >
                    Sort
                </Button>
                {activeMenu === 'sort' &&
                <Menu
                    id={'sort'}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    className={applyOrderStyles('dropMenu', 'right-view')}
                    sx={{position: 'absolute', zIndex: 999}}
                >
                    <MenuItem className={style.dropitem}>Ref number</MenuItem>
                    <MenuItem className={style.dropitem}>Brand</MenuItem>
                    <MenuItem className={style.dropitem}>Amount of order</MenuItem>
                    <MenuItem className={style.dropitem}>Order date</MenuItem>
                    <MenuItem className={style.dropitem}>Status</MenuItem>
                </Menu>
                }
            </div>
            }
            <div className={style['dropdown-custom']}>
                <Button
                    id={'export'}
                    aria-controls={'export'}
                    className={applyOrderStyles('btn', 'btn-default', 'with-shadow')}
                    sx={{
                        color: 'black',
                        backgroundColor: '#fff',
                        textTransform: 'unset',
                        border: '1px solid white',
                        fontSize: '14px',
                        borderRadius: '8px',
                        fontWeight: 600,
                        padding: '0 16px',
                        '&:hover': {
                            borderColor: '#D00209',
                            color: '#D00209',
                            '& svg': {
                                color: '#D00209'
                            }
                        },
                    }}
                    onClick={anchorEl === null ? handleOpen : handleClose}
                    startIcon={<ArrowCircleUp sx={{color: '#D00209'}}/>}
                >
                    Export
                </Button>
                {activeMenu === 'export' &&
                <Menu
                    id={'export'}
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    className={applyOrderStyles('dropMenu', 'right-view')}
                    sx={{position: 'absolute', zIndex: 999}}
                >
                    <MenuItem className={style.dropitem}>Export as .xlsx</MenuItem>
                    <MenuItem className={style.dropitem}>Export as .doc</MenuItem>
                </Menu>
                }
            </div>
            {!isSpecifiedOrder &&
            <div>
                <Button className={applyOrderStyles('btn', 'btn-default', 'with-shadow')} sx={{
                    color: 'black',
                    backgroundColor: '#fff',
                    textTransform: 'unset',
                    border: '1px solid white',
                    fontSize: '14px',
                    borderRadius: '8px',
                    fontWeight: 600,
                    padding: '0 17px',
                    '&:hover': {
                        borderColor: '#D00209',
                        color: '#D00209',
                        '& svg': {
                            color: '#D00209'
                        }
                    },
                }}
                        startIcon={<Print sx={{color: '#D00209'}}/>}
                >
                    Print
                </Button>
            </div>
            }
        </div>
    );
}
export default ToolBar