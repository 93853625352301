import * as React from 'react';
import {useState} from 'react';
import {Box, Collapse, List, ListItem, ListItemText} from '@mui/material';
import {ArrowBackIos, ArrowForwardIos, ExpandLess, ExpandMore} from "@material-ui/icons";
import {ListItemIcon} from "@material-ui/core";

const MainMenuMobile = () => {
    const [activeMenu, setActiveMenu] = useState('all')
    const [open, setOpen] = useState({});

    const handleOpen = (id) => {
        if (id === open) {
            return setOpen(false)
        }
        setOpen({...open, [id]: !open[id]})
    };

    return (
        <Box className={'mobileMenuBox'} sx={{zIndex: 10}}>
            {activeMenu === 'all' &&
            <div className="first-layer">
                <List>
                    <ListItem className="submenu" onClick={() => setActiveMenu('Admin')}>
                        <ListItemText primary="Admin"/>
                        <ListItemIcon>
                            <ArrowForwardIos/>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem className="submenu">
                        <ListItemText primary="CMS"/>
                        <ListItemIcon>
                            <ArrowForwardIos/>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem className="submenu">
                        <ListItemText primary="CRM"/>
                        <ListItemIcon>
                            <ArrowForwardIos/>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem className="submenu">
                        <ListItemText primary="Finance"/>
                        <ListItemIcon>
                            <ArrowForwardIos/>
                        </ListItemIcon>
                    </ListItem>
                    <ListItem className="submenu">
                        <ListItemText primary="Operations"/>
                        <ListItemIcon>
                            <ArrowForwardIos/>
                        </ListItemIcon>
                    </ListItem>
                </List>
            </div>
            }
            <div className="last-layer">
                {activeMenu === 'Admin' &&
                <div className="layer menu-2">
                    <ListItem className="back" onClick={() => setActiveMenu('all')}><ArrowBackIos/>Admin</ListItem>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItem onClick={() => handleOpen('Track Management')}>
                            <ListItemText
                                primary="Track Management"
                            />
                            {open['Track Management'] ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={open['Track Management']} timeout="auto" unmountOnExit>
                            <List disablePadding className={'collapsedList'}>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Management 1"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Management 2"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Management 3"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Management 4"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Management 5"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Management 6"/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem onClick={() => handleOpen('Fleet Management')}>
                            <ListItemText
                                primary="Fleet Management"
                            />
                            {open['Fleet Management'] ? <ExpandLess/> : <ExpandMore/>}
                        </ListItem>
                        <Collapse in={open['Fleet Management']} timeout="auto" unmountOnExit>
                            <List disablePadding className={'collapsedList'}>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Fleet 1"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Fleet 2"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Fleet 3"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Fleet 4"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Fleet 5"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemText sx={{pl: 4}} primary="Fleet 6"/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem>
                            <ListItemText primary="Driving Experiences"/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Experience Categories"/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Inventory"/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Pages"/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="News"/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Blocks"/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="URLs"/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="FAQs"/>
                        </ListItem>
                    </List>
                </div>
                }
            </div>
        </Box>
    );
};

export default MainMenuMobile;