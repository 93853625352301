import * as React from "react";
import {useEffect, useState} from "react";
import style from "../../styles.module.scss";
import {Box, Grid, Tab, Tabs} from "@material-ui/core";
import ToolBar from "../../Toolbar/ToolBar";
import FilterPageBody from "../../Toolbar/FilterPageBody";
import OrderDetails from "../OrderDetails";
import {Datagrid, List} from "react-admin";
import {useParams} from "react-router-dom"
import OrderId from "../../Fields/OrderId";
import OrderDate from "../../Fields/OrderDate";
import OrderExperiencesInfo from "../../Fields/OrderExperiencesInfo";
import OrderDrivers from "../../Fields/OrderDrivers";
import OrderExperiences from "../../Fields/OrderExperiences";
import OrderAddons from "../../Fields/OrderAddons";
import OrderAmount from "../../Fields/OrderAmount";
import OrderStatus from "../../Fields/OrderStatus";
import OrderActions from "../../Fields/OrderActions";
import OrderIcons from "../../Fields/OrderIcons";
import ExPagination from "../../Fields/ExPagination";

export const Order = ({dataProvider}) => {
    const [orderData, setOrderData] = useState(null)
    const [showFilters, setShowFilters] = useState(false)
    const [tabIndex, setTabIndex] = useState(0);
    const {id} = useParams()

    useEffect(() => {
        dataProvider.getOne('orders/view', {id}).then(el => setOrderData(el.data))
    }, [dataProvider, setOrderData, id])

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    return (
        <div className={style.wrap}>
            <br/>
            <Grid container spacing={2} alignItems="baseline" style={{marginBottom: '20px'}}>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <h1>Order IO{id}</h1>
                </Grid>
            </Grid>
            <OrderDetails isDetailsAvailable={false}/>
            <ToolBar isSpecifiedOrder={true} onChange={() => setShowFilters(!showFilters)}/>
            <FilterPageBody show={showFilters}/>
            <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Students"/>
                    <Tab label="Sub-orders"/>
                    <Tab label="Payments"/>
                    <Tab label="Billing & Shipping"/>
                    <Tab label="Emails & Calls"/>
                    <Tab label="History"/>
                </Tabs>
            </Box>
            <Box sx={{padding: 2}}>
                {tabIndex === 0 && (
                    <Box>
                        <List exporter={false} pagination={<ExPagination/>} className={style["table-wrap"]}
                              sx={{
                                  zIndex: 1,
                                  '& .MuiToolbar-root': {
                                      display: 'none'
                                  },
                              }}
                        >
                            <Datagrid bulkActionButtons={false}>
                                <OrderIcons/>
                                <OrderId source="Sub-orders" sortable={false}/>
                                <OrderDate source="Date of order" sortable={false}/>
                                <OrderExperiencesInfo source="Experience time" sortable={false}/>
                                <OrderDrivers source="Driver and Guest" label={"Driver and Guest"} sortable={false}/>
                                <OrderExperiences source="Experience" sortable={false}/>
                                <OrderAddons source="Add-Ons" label={"Add-Ons"} sortable={false}/>
                                <OrderAmount source="Amount" sortable={false}/>
                                <OrderStatus source="Status" sortable={false}/>
                                <OrderActions/>
                            </Datagrid>
                        </List>
                    </Box>
                )}
                {tabIndex === 1 && (
                    <Box>

                    </Box>
                )}
                {tabIndex === 2 && (
                    <Box>
                    </Box>
                )}
            </Box>
        </div>
    )
};