import exoticRacing from '../img/exotic-racing.png'
import speedVegas from '../img/speed-vegas.png'
import vegasOffRoad from '../img/vegas-offroad.png'
import vegasSuper from '../img/vegas-super.png'

export default {
    exoticRacing,
    speedVegas,
    vegasSuper,
    vegasOffRoad
}