import * as React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material';
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";


import {useSidebarState,} from 'react-admin';

const Root = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
}));

const AppFrame = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
}));

const ContentWithSidebar = styled("main")(({theme}) => ({
    display: "flex",
    flexGrow: 1,
}));

const Content = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    backgroundColor: '#F1F3F5',
    flexGrow: 2,
    padding: theme.spacing(3),
    paddingTop: "2em",
    paddingLeft: 5,
}));

const AdminLayout = ({
                         children,
                         dashboard,
                         title,
                     }) => {
    const [open] = useSidebarState();

    return (
        <Root>
            <AppFrame>
                <div>
                    <Header/>
                    <HeaderMobile/>

                    <Content>
                        {children}
                    </Content>
                </div>
            </AppFrame>
        </Root>
    );
};

AdminLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    title: PropTypes.string.isRequired,
};

export default AdminLayout;