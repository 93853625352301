import * as React from "react";
import {useState} from "react";
import {Datagrid, List} from 'react-admin';
import OrderId from '../Fields/OrderId';
import OrderAddons from '../Fields/OrderAddons';
import OrderAmount from '../Fields/OrderAmount';
import OrderDate from '../Fields/OrderDate';
import OrderDrivers from '../Fields/OrderDrivers';
import OrderExperiences from '../Fields/OrderExperiences';
import OrderExperiencesInfo from '../Fields/OrderExperiencesInfo';
import OrderStatus from '../Fields/OrderStatus';
import OrderIcons from '../Fields/OrderIcons';
import {Grid} from "@material-ui/core";
import ToolBar from "../Toolbar/ToolBar";
import FilterPageBody from "../Toolbar/FilterPageBody";
import OrderDetails from "./OrderDetails";
import OrderActions from "../Fields/OrderActions";
import ExPagination from "../Fields/ExPagination";
import style from '../styles.module.scss'

export const OrdersList = () => {
    const [showFilters, setShowFilters] = useState(false)
    return (
        <div className={style.wrap}>
            <br/>
            <Grid container spacing={2} alignItems="baseline">
                <Grid item xs={12} sm={3} md={3} lg={3}>
                    <h1>Orders list</h1>
                </Grid>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                    <ToolBar onChange={() => setShowFilters(!showFilters)}/>
                </Grid>
            </Grid>
            <FilterPageBody show={showFilters}/>
            <OrderDetails isDetailsAvailable={true}/>

            <List exporter={false} pagination={<ExPagination/>} className={style["table-wrap"]}
                  sx={{
                      zIndex: 1,
                      '& .MuiToolbar-root': {
                          display: 'none'
                      },
                  }}
            >
                <Datagrid bulkActionButtons={false}>
                    <OrderIcons/>
                    <OrderId source="Orders" sortable={false}/>
                    <OrderDate source="Date of order" sortable={false}/>
                    <OrderExperiencesInfo source="Experience time" sortable={false}/>
                    <OrderDrivers source="Driver and Guest" label={"Driver and Guest"} sortable={false}/>
                    <OrderExperiences source="Experience" sortable={false}/>
                    <OrderAddons source="Add-Ons" label={"Add-Ons"} sortable={false}/>
                    <OrderAmount source="Amount" sortable={false}/>
                    <OrderStatus source="Status" sortable={false}/>
                    <OrderActions/>
                </Datagrid>
            </List>
        </div>
    )
};