import {useListContext} from 'react-admin';
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import React from "react";
import style from '../styles.module.scss'
import {applyOrderStyles} from "../../helpers/apply-order-styles";

const ExPagination = () => {
    const {page, hasPreviousPage, hasNextPage, setPage, total} = useListContext();
    if (!hasPreviousPage && !hasNextPage) return null;

    const fromResult = page * 10 - 9,
        toResult = fromResult + 9,
        lastPage = Math.round(total / 10) + 1;

    let pages = [9, 10, 11];
    if (page > 2 && page < lastPage) {
        pages = [page - 1, page, page + 1];
    } else if (page === 2) {
        pages = [2, 3];
    }


    return (
        <div className={style["paging"]}>
            <div>
                <div
                    className={applyOrderStyles('text-gray-3', 'body3-r')}>Displaying {fromResult} - {toResult} of {total} results.
                </div>
            </div>
            <div className={style["text-right"]}>
                <ul className={style["pagination"]}>
                    <li>
                        {hasPreviousPage ?
                            <a className="DDD"><KeyboardArrowLeft className={style["material-icons-outlined"]}
                                                                  onClick={() => setPage(page - 1)}/></a> : ''}
                    </li>

                    <li className={page === 1 ? 'active' : ''} onClick={() => setPage(1)}><a className="DDD">1</a></li>
                    <li>...</li>

                    {pages.map((object, i) => {
                        return <li key={i} className={page === object ? 'active' : ''} onClick={() => setPage(object)}>
                            <a
                                className="DDD">{object}</a></li>
                    })}

                    <li>...</li>


                    <li className={page === lastPage ? 'active' : ''} onClick={() => setPage(lastPage)}><a
                        className="DDD">{lastPage - 1}</a></li>

                    {hasNextPage ?
                        <li><a className="DDD"><KeyboardArrowRight className={style["material-icons-outlined"]}
                                                                   onClick={() => setPage(page + 1)}/></a>
                        </li> : ''}

                </ul>
            </div>
        </div>
    );
}

export default ExPagination;


