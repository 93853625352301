import style from '../styles.module.scss'
import {applyOrderStyles} from "../../helpers/apply-order-styles";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import MuiPhoneNumber from "material-ui-phone-number";


const FilterPageBody = ({show}) => {
    return (
        <div className={`${style["filter-page-body"]} ${show && style.open}`}>
            <div>
                <div className={style["form-group"]}>
                    <label>Company</label>
                    <select className={style["form-control"]}>
                        <option value="0">All</option>
                        <option value="1">LA track</option>
                    </select>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Reference #</label>
                    <input type="text" className={style["form-control"]} placeholder="Reference #"/>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Purchaser</label>
                    <input type="text" className={style["form-control"]} placeholder="Purchaser"/>
                </div>
            </div>
            <div className={style["form-group"]}>
                <label>Purchaser</label>
                <MuiPhoneNumber defaultCountry={"us"} className={style["form-control"]} sx={{
                    marginTop: 0,
                    justifyContent: 'end',
                    '& .MuiInputBase-root': {
                        paddingLeft: '9px',
                        '::before': {
                            display: 'none'
                        },
                        '::after': {
                            display: 'none'
                        }
                    },
                    '& .MuiInputBase-input': {
                        padding: 0
                    },
                }}/>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Products</label>
                    <input type="text" className={style["form-control"]} placeholder="Products"/>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Tracks & Dates</label>
                    <select className={style["form-control"]}>
                        <option value="0">All tracks & dates</option>
                        <option value="1">LA track</option>
                    </select>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Student</label>
                    <input type="text" className={style["form-control"]} placeholder="Student"/>
                </div>
            </div>
            <div className={style["date-field"]}>
                <div className={style["form-group"]}>
                    <label>Order date from</label>
                    <input type="text" className={style["form-control"]} placeholder="dd.mm.yyyy"/>
                    <span className={applyOrderStyles("material-icons-outlined", "right-ico")}>
                        <CalendarTodayOutlinedIcon fontSize={'small'}/>
                    </span>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>&nbsp;</label>
                    <input type="text" className={style["form-control"]} placeholder="dd.mm.yyyy"/>
                    <span className={applyOrderStyles("material-icons-outlined", "right-ico")}>
                        <CalendarTodayOutlinedIcon fontSize={'small'}/>
                    </span>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Commercial</label>
                    <select className={style["form-control"]}>
                        <option value="0">All</option>
                        <option value="1">LAtrack</option>
                    </select>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Commercial name</label>
                    <input type="text" className={style["form-control"]} placeholder="Commercial name"/>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Discount or Retailer code</label>
                    <input type="text" className={style["form-control"]} placeholder="Discount or Retailer code"/>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Total</label>
                    <input type="text" className={style["form-control"]} placeholder="Total"/>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Order status</label>
                    <select className={style["form-control"]}>
                        <option value="0">All</option>
                        <option value="1">LAtrack</option>
                    </select>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Booking location</label>
                    <select className={style["form-control"]}>
                        <option value="0">All</option>
                        <option value="1">LAtrack</option>
                    </select>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Knew us from</label>
                    <select className={style["form-control"]}>
                        <option value="0">All</option>
                        <option value="1">LAtrack</option>
                    </select>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Occasion</label>
                    <select className={style["form-control"]}>
                        <option value="0">All</option>
                        <option value="1">LAtrack</option>
                    </select>
                </div>
            </div>
            <div>
                <div className={style["form-group"]}>
                    <label>Paid status</label>
                    <select className={style["form-control"]}>
                        <option value="0">All</option>
                        <option value="1">LAtrack</option>
                    </select>
                </div>
            </div>
            <div className={style.actions}>
                <button className={applyOrderStyles("btn", "btn-red")}>Search
                </button>
                <button className={applyOrderStyles("btn", "btn-dark")}>Reset</button>
            </div>

        </div>
    );
}
export default FilterPageBody