// in dataProvider.js
import {fetchUtils} from 'ra-core';
import inMemoryJWT from '../auth/inMemoryJwt';


export default (apiUrl) => {
    const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;
    const httpClient = (url) => {
        const options = {
            headers: new Headers({Accept: 'application/json'}),
        };
        options.headers.set('Access-Control-Expose-Headers', 'X-Total-Count');
        const token = inMemoryJWT.getToken();

        if (token) {
            options.headers.set('Authorization', `Bearer ${token}`);
            return fetchUtils.fetchJson(url, options);
        } else {
            inMemoryJWT.setRefreshTokenEndpoint(authEndpoint + 'refresh');
            return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                if (gotFreshToken) {
                    options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
                }
                ;
                return fetchUtils.fetchJson(url, options);
            });
        }
    };

    return {
        getList: (resource, params) => {
            const url = `${apiUrl}/${resource}/?page=${params.pagination.page}`;
            return httpClient(url).then(({headers, json}) => {

                console.log(json.data);

                return {
                    data: json.data.data,
                    total: json.data.total,
                };
            });
        },
        getOne: (resource, params) =>
            httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
                data: json,
            })),
        getMany: () => Promise.reject(),
        getManyReference: () => Promise.reject(),
        update: () => Promise.reject(),
        updateMany: () => Promise.reject(),
        create: () => Promise.reject(),
        delete: () => Promise.reject(),
        deleteMany: () => Promise.reject(),
    };
};