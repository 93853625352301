import * as React from "react";
import {useRecordContext} from 'react-admin';
import {ArrowCircleUp, CameraAltOutlined, EmailOutlined} from "@mui/icons-material";
import style from '../styles.module.scss'
import {applyOrderStyles} from "../../helpers/apply-order-styles";

const OrderAddons = ({}) => {
    const record = useRecordContext();

    return record ? (
        <div style={{minWidth: '150px'}}>
            <div className={style["mb-16"]}>
                <ArrowCircleUp className={applyOrderStyles('table-icon', 'material-icons-outlined')}/>
                <EmailOutlined className={applyOrderStyles('table-icon', 'material-icons-outlined')}/>
                <CameraAltOutlined className={applyOrderStyles('table-icon', 'material-icons-outlined')}/>
            </div>
            <div className={`${style["mb-8"]} ${style["text-gray-3"]}`}>1/4 Add-Ons</div>
        </div>
    ) : null;
}

export default OrderAddons;