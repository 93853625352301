import * as React from 'react';
import {useCallback, useState} from 'react';
import UserDropdown from "./UserDropdown";
import {
    AddCircleOutline,
    CalendarTodayOutlined,
    FolderOutlined,
    HomeOutlined,
    MapOutlined,
    NotificationsNoneOutlined,
    Search,
    SettingsOutlined,
} from '@mui/icons-material';
import {Button} from "@mui/material";

const HeadActionButton = () => {
    const [active, setActive] = useState('')
    const onClick = useCallback((e) => {
        setActive(e.currentTarget.id)
    }, [setActive])

    return (
        <div className='action-btn-container'>
            <div className="btn-box">
                <a id={'home'} onClick={onClick} className={active === 'home' ? "btn activeBtn" : 'btn'}><HomeOutlined/></a>
                <a id={'calendar'} onClick={onClick}
                   className={active === 'calendar' ? "btn activeBtn" : 'btn'}><CalendarTodayOutlined/></a>
                <a id={'folder'} onClick={onClick}
                   className={active === 'folder' ? "btn activeBtn" : 'btn'}><FolderOutlined/></a>
                <a id={'search'} onClick={onClick}
                   className={active === 'search' ? "btn activeBtn" : 'btn'}><Search/></a>
                <a id={'map'} onClick={onClick}
                   className={active === 'map' ? "btn activeBtn" : 'btn'}><MapOutlined/></a>
                <a id={'settings'} onClick={onClick}
                   className={active === 'settings' ? "btn activeBtn" : 'btn'}><SettingsOutlined/></a>
            </div>
            <Button className="btn add-action" startIcon={<AddCircleOutline/>}>New order</Button>
            <button className="btn notify"><NotificationsNoneOutlined/><span>2</span></button>
            <UserDropdown/>
        </div>
    );
};

export default HeadActionButton;