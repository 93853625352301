import * as React from 'react';
import {useState} from 'react';
import {IconButton, Link} from '@mui/material';
import MainMenuMobile from "./MainMenuMobile";
import UserDropdown from "./UserDropdown";
import {AddCircleOutline, NotificationsNoneOutlined} from '@mui/icons-material';
import MenuIcon from "@mui/icons-material/Menu";
import {Close} from "@material-ui/icons";

const HeaderMobile = () => {
    const [openMenu, setOpenMenu] = useState(false)

    const handleOpen = (e) => {
        setOpenMenu(true)
    };

    const handleClose = () => {
        setOpenMenu(false)
    };

    return (
        <header className="mobile-head">
            <div className="wrap">
                <div style={{display: 'flex'}}>
                    <IconButton
                        className="btn mobile-menu-button"
                        sx={{'& svg': {width: '100%', height: '100%'}}}
                        onClick={openMenu ? handleClose : handleOpen}
                    >
                        {openMenu ? <Close/> : <MenuIcon/>}
                    </IconButton>
                    {openMenu && <MainMenuMobile/>}
                    <button className="btn add-action"><AddCircleOutline/></button>
                </div>
                <div>
                    <Link color="inherit" href="/" className="logo"></Link>
                    <div className="main-menu-wrap main-menu-mobile">
                        <MainMenuMobile/>
                    </div>
                </div>
                <div>
                    <button className="btn notify"><NotificationsNoneOutlined/><span>2</span></button>
                    <UserDropdown/>
                </div>
            </div>
        </header>
    );
};

export default HeaderMobile;