import * as React from "react";
import {useState} from "react";
import {Notification, useLogin, useNotify} from "react-admin";
import {ThemeProvider} from "@material-ui/styles";
import {colors, createTheme} from "@material-ui/core";
import {Login} from '@mui/icons-material';
import {Button, InputLabel, TextField} from "@mui/material";

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const login = useLogin();
    const notify = useNotify();
    const submit = e => {
        e.preventDefault();
        login({username, password}).catch(() => notify("Invalid email or password"));
    };

    // Create a theme instance.
    const theme = createTheme({
        palette: {
            primary: {
                main: '#556cd6',
            },
            secondary: {
                main: '#19857b',
            },
            error: {
                main: colors.red.A400,
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <form className="login-page" onSubmit={submit}>
                <div className="line line-1"></div>
                <div className="line line-2"></div>
                <div className="line line-3"></div>
                <div className="wrap-login">
                    <div className="logo"></div>
                    <div className="box">
                        <div className="title">Welcome to EXR!</div>
                        <div className="form-group" style={{minWidth: 340}}>
                            <InputLabel>Email</InputLabel>
                            <TextField
                                name="username"
                                variant="standard"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                placeholder="Enter your email"
                                className="form-control"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: 14,
                                        padding: '0 3px',
                                        '&::placeholder': {
                                            fontWeight: 500,
                                            color: '#18191C',
                                            opacity: 0.5
                                        }
                                    }
                                }}
                                InputProps={{disableUnderline: true}}
                            />
                        </div>
                        <div className="form-group" style={{minWidth: 340}}>
                            <InputLabel>Password</InputLabel>
                            <TextField
                                name="password"
                                type="password"
                                variant="standard"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                className="form-control"
                                sx={{
                                    '& .MuiInputBase-input': {
                                        fontSize: 14,
                                        padding: '0 3px',
                                        '&::placeholder': {
                                            fontWeight: 500,
                                            color: '#18191C',
                                            opacity: 0.5
                                        }
                                    },
                                }}

                                InputProps={{disableUnderline: true}}
                            />
                        </div>
                        <div className="forgot-link">
                            <a href="/">Forgot password?</a>
                        </div>
                        <div>
                            <Button
                                type={'submit'}
                                sx={{
                                    color: '#fff',
                                    backgroundColor: '#D00209',
                                    textTransform: 'unset',
                                    fontSize: '14px',
                                    borderRadius: '8px',
                                    fontWeight: 400,
                                    '&:hover': {
                                        backgroundColor: '#B70006',
                                    },
                                }}
                                className="btn btn-red wide"
                                endIcon={<Login sx={{fontSize: '20px !important'}}/>}
                            >
                                Log in
                            </Button>
                        </div>
                    </div>
                </div>


            </form>
            <Notification/>
        </ThemeProvider>
    );
};

export default LoginPage;
