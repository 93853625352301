import * as React from "react";
import {useRecordContext} from 'react-admin';
import {applyOrderStyles} from "../../helpers/apply-order-styles";

const OrderStatus = () => {
    const record = useRecordContext();

    let orderStatusMark = '';

    switch (record.orderstatus_id) {
        case 1:
            orderStatusMark =
                <span className={applyOrderStyles("order-status-yellow", 'order-status')}>Pending</span>;
            break;
        case 4:
            orderStatusMark =
                <span className={applyOrderStyles("order-status-yellow", 'order-status')}>Completed</span>;
            break;
        // case 'Vegassuperkarts':
        //     locationImg = img.vegasSuper;
        // break;
        // case 'Offroad':
        //     locationImg = img.vegasOffRoad;
        // break;
        /*


                    <div className="mb-8"><span className="Orders-status Orders-status-yellow">Pending</span>
            </div>
            <div className="mb-8"><span className="Orders-status Orders-status-green">Pending</span>
            </div>
            <div className="mb-8"><span className="Orders-status Orders-status-red">Pending</span>
            </div>
            <div className="mb-8"><span className="Orders-status Orders-status-orange">Pending</span>
            </div>
            <div className="mb-8"><span className="Orders-status Orders-status-blue">Pending</span>
            </div>
            <div><span className="Orders-status Orders-status-purple">Pending</span></div>


         */
    }


    return record ? (
        <div>
            <div className="mb-8">
                {orderStatusMark}
            </div>
        </div>
    ) : null;
}

export default OrderStatus;