import React from "react";
import {Admin, Resource} from 'react-admin';
import {OrdersList} from './components/Orders/OrdersList';
import {PostCreate, PostEdit, PostList} from './components/Posts/Posts';
import authProvider from './providers/auth/authProvider';
import myDataProvider from './providers/data/dataProvider';
import LoginPage from "./components/Auth/Login";
import AdminLayout from "./layout/AdminLayout";
import './css/styles.scss'
import {Order} from "./components/Orders/ViewOrder/Order";


const dataProvider = myDataProvider(process.env.REACT_APP_API_ENDPOINT);

const App = () => (
    <>
        <Admin
            layout={AdminLayout}
            authProvider={authProvider}
            dataProvider={dataProvider}
            loginPage={LoginPage}
        >
            <Resource name="orders" list={OrdersList} show={<Order dataProvider={dataProvider}/>}/>
            <Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate}/>
        </Admin>
    </>
);

export default App;
