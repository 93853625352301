import * as React from "react";
import {useRecordContext} from 'react-admin';
import img from "../../img";
import style from '../styles.module.scss'
import {applyOrderStyles} from "../../helpers/apply-order-styles";
import {Link} from "react-router-dom";

const OrderId = () => {
    const record = useRecordContext();

    // console.log(record);

    let locationImg = img.exoticRacing;

    switch (record.order_booking_location?.name) {
        case 'Speedvegas':
            locationImg = img.speedVegas;
            break;
        case 'Vegassuperkarts':
            locationImg = img.vegasSuper;
            break;
        case 'Offroad':
            locationImg = img.vegasOffRoad;
            break;
    }


    return record ? (
        <div>
            <div className={style["mb-8"]}><Link to={`/orders/${record.id}/show`} className={style["body2-b"]}>I0{record.id}</Link></div>
            <div className={style["mb-8"]}><a href="#">{record.customer.firstname} {record.customer.lastname}</a></div>
            <div className={applyOrderStyles("text-gray-3", 'mb-8')}>{record.commercial_type}</div>
            <div className={applyOrderStyles("text-gray-3", 'mb-16')}>{record.commercial_name}</div>
            <div><img src={locationImg} alt=""/></div>
        </div>
    ) : null;
}

export default OrderId;