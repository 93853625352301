import * as React from "react";
import {useRecordContext} from 'react-admin';
import Moment from 'moment'
import {applyOrderStyles} from "../../helpers/apply-order-styles";

const OrderDate = () => {
    const record = useRecordContext();

    const formatDate = Moment(record.cr_date).format('MM.DD.YYYY'),
        formatTime = Moment(record.cr_date).format('HH:m A');

    return record ? (
        <div>
            <div className={applyOrderStyles('mb-16', 'body2-b')}>
                {formatDate}<br/>{formatTime}
            </div>
        </div>
    ) : null;
}

export default OrderDate;