import * as React from 'react';
import {Link} from '@mui/material';
import MainMenu from "./MainMenu";
import HeadActionButton from "./HeadActionButton";
import {Close, Menu} from '@mui/icons-material';

const Header = () => {
    return (
        <header style={{
            position: 'fixed',
            zIndex: 2,
            width: '100%'
        }}>
            <div className="wrap">
                <div>
                    <button className="btn mobile-menu-button">
                        <Menu/>
                        <Close/>
                    </button>
                    <Link color="inherit" href="/" className="logo"></Link>
                    <div className="main-menu-wrap ">
                        <MainMenu/>
                    </div>
                </div>
                <div>
                    <HeadActionButton/>
                </div>
            </div>
        </header>
    );
};

export default Header;