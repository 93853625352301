import * as React from "react";
import {useState} from "react";
import {useRecordContext} from 'react-admin';
import {IconButton, Menu, MenuItem} from '@mui/material';
import {ErrorOutline, Notifications, NotificationsNone} from "@mui/icons-material";
import style from '../styles.module.scss'
import {applyOrderStyles} from "../../helpers/apply-order-styles";

const OrderIcons = ({source}) => {
    const record = useRecordContext();
    const [activeMenu, setActiveMenu] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
        setActiveMenu(e.currentTarget.id)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return record ? (
        <div className="text-center">
            <div style={{marginBottom: '7px'}}>
                <div className={style["dropdown-custom"]}>
                    <IconButton
                        onClick={handleOpen}
                        id={'notification'}
                        sx={{
                            padding: '0 0 10px 0'
                        }}
                    >
                        <NotificationsNone className={applyOrderStyles('table-icon', 'material-icons-outlined')}/>
                    </IconButton>
                    {activeMenu === 'notification' &&
                    <Menu
                        id={'notification'}
                        className={style.dropMenu}
                        open={open}
                        anchorEl={anchorEl}
                        onClick={handleClose}
                    >
                        <MenuItem className={style.dropitem}>
                            <Notifications/>Set notification
                        </MenuItem>
                    </Menu>
                    }
                </div>
                <div className={style["dropdown-custom"]}>
                    <IconButton
                        onClick={handleOpen}
                        id={'info'}
                        sx={{
                            padding: 0
                        }}
                    >
                        <ErrorOutline className={applyOrderStyles('table-icon', 'material-icons-outlined')}/>
                    </IconButton>
                    {activeMenu === 'info' &&
                    <Menu
                        id={'info'}
                        className={style.dropMenu}
                        open={open}
                        anchorEl={anchorEl}
                        onClick={handleClose}
                    >
                        <MenuItem className={style.dropitem}>
                            <ErrorOutline/>Info
                        </MenuItem>
                    </Menu>
                    }
                </div>
            </div>
        </div>
    ) : null;
}

export default OrderIcons;