import * as React from "react";
import {useRecordContext} from 'react-admin';
import Moment from 'moment'
import '../styles.module.scss'
import style from "../styles.module.scss";
import {applyOrderStyles} from "../../helpers/apply-order-styles";

const OrderExperiencesInfo = ({source}) => {
    const record = useRecordContext();
    return record ? (
        <div>
            {record.experiensesTrackDate.map((object, i) => {
                return <div key={i}>
                    <div
                        className={applyOrderStyles('body2-b', 'mb-16')}>{Moment(object.date.date).format('dddd MMMM DD, YYYY')} {object.session.from}</div>
                    <div className={style["mb-8"]}>{object.track.name}</div>
                    <br/>
                </div>;
            })}
        </div>
    ) : null;
}

export default OrderExperiencesInfo;