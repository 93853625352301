import * as React from 'react';
import {ExpandMore} from '@mui/icons-material';

const UserDropdown = () => {
    return (
        <div className="user-dropdown">
            <div className="top">
                <div className="avatar"
                     style={{backgroundImage: 'url("https://www.hotjar.com/_next/image?url=https%3A%2F%2Fimages.ctfassets.net%2Flh3zuq09vnm2%2FyBDals8aU8RWtb0xLnPkI%2F19b391bda8f43e16e64d40b55561e5cd%2FHow_tracking_user_behavior_on_your_website_can_improve_customer_experience.png&w=3840&q=75")'}}></div>
                <div className="name">
                    <span>James</span>
                    <ExpandMore/>
                </div>
            </div>
            <div className="down">
                <ul>
                    <li><a href="#">Profile</a></li>
                    <li><a href="#">Admin</a></li>
                    <li><a href="#">Admin</a></li>
                    <li><a href="#">Logout</a></li>
                </ul>
            </div>
        </div>
    );
};

export default UserDropdown;