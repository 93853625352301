const inMemoryJWTManager = () => {
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = process.env.REACT_APP_AUTH_ENDPOINT + 'refresh';
    let inMemoryJWT = null;
    let refreshTimeOutId;
    let isRefreshing = null;

    // This listener allows to disconnect another session of react-admin started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // This countdown feature is used to renew the JWT in a way that is transparent to the user.
    // before it's no longer valid
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    };

    const getRefreshedToken = () => {
        let token = getToken();
        if (token == null) {
            token = window.localStorage.getItem('token');
        }
        const request = new Request(refreshEndpoint, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }),
            // credentials: 'include',
        });

        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                    global.console.log(
                        'Token renewal failure'
                    );
                    return {token: null};
                }
                return response.json();
            })
            .then(({token, tokenExpiry}) => {
                if (token) {
                    setToken(token, tokenExpiry);
                    return true;
                }
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };


    const getToken = () => inMemoryJWT;

    // const getToken = () => {
    //     let token = null;
    //     if (inMemoryJWT == null) {
    //         token = window.localStorage.getItem('token');
    //     }
    //     return inMemoryJWT ? inMemoryJWT : token;
    // };

    const setToken = (token, delay) => {
        inMemoryJWT = token;
        window.localStorage.setItem('token', token);

        refreshToken(delay);
        return true;
    };

    const ereaseToken = () => {
        inMemoryJWT = null;
        abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        window.localStorage.setItem('token', '');
        return true;
    };

    const setLogoutEventName = name => logoutEventName = name;

    return {
        ereaseToken,
        getToken,
        getRefreshedToken,
        setLogoutEventName,
        waitForTokenRefresh,
        setRefreshTokenEndpoint,
        setToken,
    }
};

export default inMemoryJWTManager();