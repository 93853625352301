import inMemoryJWT from './inMemoryJwt';


const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;
const authProvider = {
    login: ({username, password}) => {
        const request = new Request(authEndpoint + 'login', {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
            // credentials: 'include',
        });
        inMemoryJWT.setRefreshTokenEndpoint(authEndpoint + 'refresh');
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({token, tokenExpiry}) => inMemoryJWT.setToken(token, tokenExpiry));
    },


    logout: () => {
        inMemoryJWT.ereaseToken();
        return Promise.resolve();
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    checkAuth: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
        });
    },
    getPermissions: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
        });
    },
};

export default authProvider;