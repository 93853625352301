import * as React from "react";
import {useRecordContext} from 'react-admin';
import style from '../styles.module.scss'

const OrderExperiences = () => {
    const record = useRecordContext();

    return record ? (
        <div>
            <div className={style["experience-status"]}>
                {Object.entries(record.orderDrivingExperienses).map((t, k) => <span key={k} style={{
                    backgroundColor: t[1]['vehicle']['codecolor'],
                    color: t[1]['vehicle']['code_font_color']
                }}><i>{t[1]['vehicle']['code']}</i>{t[1]['drivingExperience']['laps']}</span>)}
            </div>
        </div>
    ) : null;
}

export default OrderExperiences;