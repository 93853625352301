import * as React from 'react';
import {Link} from '@mui/material';
import {ArrowForwardIos} from '@mui/icons-material';

const MainMenu = () => {
    return (
        <ul className="main-menu">
            <li><Link color="inherit" href="/">Admin</Link></li>
            <li className="submenu">
                <Link color="inherit" href="/">CMS <span><ArrowForwardIos/></span></Link>
                <div style={{zIndex: 3}}>
                    <ul data-level="2">
                        <li className="submenu">
                            <Link color="inherit" className="active" href="/">Track Management <span><ArrowForwardIos/></span></Link>
                            <ul data-level="3">
                                <li><Link color="inherit" href="/">Management 1</Link></li>
                                <li><Link color="inherit" className="active" href="/">Management 2</Link></li>
                                <li><Link color="inherit" href="/">Management 3</Link></li>
                                <li><Link color="inherit" href="/">Management 4</Link></li>
                                <li><Link color="inherit" href="/">Management 5</Link></li>
                                <li><Link color="inherit" href="/">Management 6</Link></li>
                            </ul>
                        </li>
                        <li className="submenu">
                            <Link color="inherit" href="/">Fleet Management <span><ArrowForwardIos/></span></Link>
                            <ul data-level="3">
                                <li><Link color="inherit" href="/">Fleet 1</Link></li>
                                <li><Link color="inherit" href="/">Fleet 2</Link></li>
                                <li><Link color="inherit" href="/">Fleet 3</Link></li>
                                <li><Link color="inherit" href="/">Fleet 4</Link></li>
                                <li><Link color="inherit" href="/">Fleet 5</Link></li>
                                <li><Link color="inherit" href="/">Fleet 6</Link></li>
                            </ul>
                        </li>
                        <li><Link color="inherit" href="/">Driving Experiences</Link></li>
                        <li><Link color="inherit" href="/">Experience Categories</Link></li>
                        <li className="submenu">
                            <Link color="inherit" href="/">Inventory<span><ArrowForwardIos/></span></Link>
                            <ul data-level="3">
                                <li><Link color="inherit" href="/">Fleet 1</Link></li>
                                <li><Link color="inherit" href="/">Fleet 2</Link></li>
                                <li><Link color="inherit" href="/">Fleet 3</Link></li>
                                <li><Link color="inherit" href="/">Fleet 4</Link></li>
                                <li><Link color="inherit" href="/">Fleet 5</Link></li>
                                <li><Link color="inherit" href="/">Fleet 6</Link></li>
                            </ul>
                        </li>
                        <li><Link color="inherit" href="/">Pages</Link></li>
                        <li><Link color="inherit" href="/">News</Link></li>
                        <li><Link color="inherit" href="/">Blocks</Link></li>
                        <li><Link color="inherit" href="/">URLs</Link></li>
                        <li><Link color="inherit" href="/">FAQs</Link></li>
                    </ul>
                </div>

            </li>
            <li><Link color="inherit" href="/">CRM</Link></li>
            <li><Link color="inherit" href="/">Finance</Link></li>
            <li><Link color="inherit" href="/">Operations</Link></li>
        </ul>
    );
};

export default MainMenu;